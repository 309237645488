<template>
	<v-container fluid fill-height>
		<v-row align="center" justify="center" no-gutters>
      <v-col cols="12">
        <base-heading :title="title">
					<base-btn v-if="products.length>0" dark color="green darken-1" @click="mainReportView()" icon="mdi-file-chart" btnText="Reporte"></base-btn>
				</base-heading>
      </v-col>
    </v-row>
    <v-row>
			<v-col cols="12">
				<base-data-iterator
					v-model="sortBy"
					:headers="headers" 
					:items="products" 
          :showDialog="dialog"
					@loadDataTable="toList"
          @newItem="open"
        > 
					<template v-slot:content>
            <base-form-stepper ref="ProductForm"
              :formTitle="formTitle"
              :step="step"
              :steps="steps"
              :formLoading="formLoading"
              @prevStepButton="step--"
              @nextStepButton="step++"
              @cancelButton="close"
              @saveButton="save"
            >
							<template v-slot:before>
                <v-container class="no-padding">
                	<v-row>
										<v-col cols="6" sm="6" md="6">
											<base-text-field v-model="editedItem.customCode" label="Código" counter maxlength="20" 
											:rules="[commonValRules.required, validationRules.customCodeRules.counter]" required></base-text-field>
										</v-col>
										<v-col cols="12" sm="6" md="6">
											<base-select v-model="editedItem.categoryId" :items="categories" label="Categoría"
											:rules="[commonValRules.required]" required>
												<template v-slot:selection="{ item }">
													<span v-if="!item.enabled" class="red--text">
														{{ item.text }} <span class="caption">(Inactivo)</span>
													</span>
													<span v-else>{{ item.text }}</span>
												</template>
											</base-select>
										</v-col>
									</v-row>
                </v-container>
              </template>

              <template v-slot:formContent-1>
								<v-row justify="center">
									<v-col cols="12" sm="8">
										<base-text-field v-model="editedItem.visualName" label="Nombre" counter maxlength="50" 
										:rules="[commonValRules.required, validationRules.visualNameRules.counter]" required></base-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="6">
										<base-text-field v-model="editedItem.maker" label="Marca" counter maxlength="50" 
										:rules="[commonValRules.required, validationRules.makerRules.counter]" required></base-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="6">
										<base-select v-model="editedItem.madeIn" :items="countries" label="Hecho en" 
										:rules="[commonValRules.required]" required></base-select>
									</v-col>

									<v-scale-transition v-if="showPurchasesBtn" v-model="purchasesAvailable" mode="out-in">
										<v-col v-if="!purchasesAvailable" key="GetPurchases" cols="6" sm="4" align="center">
											<base-btn enableSmallBtn dark class="mt-2" color="secondary" @click="getAvailablePurchases()" icon="mdi-cart-arrow-down" btnText="Ver compras"></base-btn>
										</v-col>
										<v-col v-else key="Purchases" cols="12" sm="6" align="center">
											<v-list subheader dense>
												<v-subheader class="font-weight-bold uppercase justify-center">Compras con stock disponible</v-subheader>

												<v-list-item>
													<v-list-item-content>Compra</v-list-item-content>
													<v-list-item-content class="justify-center">Costo unit.</v-list-item-content>
												</v-list-item>

												<v-responsive
												class="overflow-y-auto"
												max-height="150"
											>
												<v-list-item
													v-for="purchase in purchases"
													:key="purchase.purchaseId"
												>
													<v-list-item-content class="font-weight-bold" :class="{ 'blue--text': editedItem.lastPurchaseId == purchase.purchaseId }">{{ purchase.purchaseId }}:</v-list-item-content>
													<v-list-item-content class="align-end" :class="{ 'blue--text': editedItem.lastPurchaseId == purchase.purchaseId }">
														<template>
															<span :class="{'red--text font-weight-bold': purchase.unitCost >= editedItem.salePricePerUnit}">Bs. {{ purchase.unitCost }}</span>
														</template>
													</v-list-item-content>
												</v-list-item>
												</v-responsive>


												<!-- <v-virtual-scroll
													:items="purchases"
													:item-height="50"
													height="300"
												>
													<template v-slot:default="{ purchase }">
														<v-list-item>
															<v-list-item-content class="font-weight-bold" :class="{ 'blue--text': editedItem.lastPurchaseId == purchase.purchaseId }">{{ purchase.purchaseId }}:</v-list-item-content>
															<v-list-item-content class="align-end" :class="{ 'blue--text': editedItem.lastPurchaseId == purchase.purchaseId }">
																<template>
																	<span :class="{'red--text font-weight-bold': purchase.unitCost >= editedItem.salePricePerUnit}">Bs. {{ purchase.unitCost }}</span>
																</template>
															</v-list-item-content>
														</v-list-item>
													</template>
    										</v-virtual-scroll> -->

											</v-list>
										</v-col>
									</v-scale-transition>

									<v-col v-if="!editData" cols="6" sm="4" md="4" align="center">
										<base-currency-field 
											label="Costo unit."
											v-model="editedItem.purchasePricePerUnit"
											:rules="[validationRules.purchasePricePerUnitRules.number]" 
											required
										></base-currency-field>
									</v-col>
									
									<v-col cols="6" sm="4" md="4" align="center">
										<base-currency-field 
											label="Precio unit."
											v-model="editedItem.salePricePerUnit"
											:rules="[validationRules.salePricePerUnitRules.number, salePriceComparisonRule]" 
											required
										></base-currency-field>
									</v-col>
								</v-row>	
              </template>

							<template v-slot:formContent-2>
								<v-row justify="center">
									<v-col cols="12" sm="6" md="6">
										<base-select v-model="editedItem.stockFormId" :items="stockForms" label="Formulario de stock" @change="stockFormOnChange"
										:rules="[commonValRules.required]" required :disabled="editData"></base-select>
									</v-col>
									<v-col cols="12" sm="4" md="4">
										<base-text-field v-if="editData" class="centered-input" type="number" placeholder="0" v-model="editedItem.stock" label="Stock total" maxlength="6"
										:rules="[commonValRules.required, validationRules.stockRules.counter, validationRules.stockRules.number, validationRules.stockRules.isValid]" required disabled></base-text-field>

										<template v-else>
											<base-number-field v-if="editedItem.stockFormId === $Const.STOCK_FORM.GENERAL" fullWidth placeholder="0" v-model="editedItem.stock" label="Stock total" maxlength="6"
											:rules="[validationRules.stockRules.counter, validationRules.stockRules.number, validationRules.stockRules.isValid]" required></base-number-field>

											<base-text-field v-else class="centered-input" type="number" placeholder="0" v-model="stockDetailsSum" label="Stock total" maxlength="6"
											:rules="[commonValRules.required, validationRules.stockRules.counter, validationRules.stockRules.number, validationRules.stockRules.isValid]" required readonly></base-text-field>
										</template>
									</v-col>
								</v-row>

								<base-form-stock-details v-if="editedItem.stockFormId != ''" :stockFormId="editedItem.stockFormId" :title="editedStockDetails.title" :details="editedStockDetails.items" :mode="stockDetailsMode">
								</base-form-stock-details>
							</template>

              <template v-slot:formContent-3>
                <v-row>
									<v-col cols="12" sm="6" md="6">
										<base-upload-file :title="'Principal'" :name="mainImage" v-bind:image="editedImages.mainImage" v-on:onChange="onFileChange" :accept="allowedFiles">
										</base-upload-file>
									</v-col>
									<v-col cols="12" sm="6" md="6">
										<base-upload-file :title="'Secundaria 1'" :name="imageOne" v-bind:image="editedImages.imageOne" v-on:onChange="onFileChange" :accept="allowedFiles">
										</base-upload-file>
									</v-col>
									<v-col cols="12" sm="6" md="6">
										<base-upload-file :title="'Secundaria 2'" :name="imageTwo" v-bind:image="editedImages.imageTwo" v-on:onChange="onFileChange" :accept="allowedFiles">
										</base-upload-file>
									</v-col>
									<v-col cols="12" sm="6" md="6">
										<base-upload-file :title="'Secundaria 3'" :name="imageThree" v-bind:image="editedImages.imageThree" v-on:onChange="onFileChange" :accept="allowedFiles">
										</base-upload-file>
									</v-col>
								</v-row>
              </template>

							<template v-slot:formContent-4>
								<v-row>
									<v-col cols="12" sm="6" md="6">
										<base-select v-model="editedItem.productFormId" :items="productForms" label="Formulario de detalles" @change="productFormOnChange"
										:rules="[commonValRules.required]" required :disabled="editData"></base-select>
									</v-col>

									<v-col v-if="editedItem.productFormId != '' && editedItem.productFormId != '1'" cols="12" class="py-0">
										<base-form-product-details :productFormId="editedItem.productFormId" :details="editedProductDetails.items">
										</base-form-product-details>
									</v-col>

									<v-col cols="12">
										<base-form-container
											title="Detalles adicionales"
											titleClasses="secondary accent-4 white--text"
											noPaddingContent
										>
											<template v-slot:titleContent>
												<v-row no-gutters class="justify-center justify-sm-end" align="center">
													<base-icon-btn fabMode btnColor="primary" color="white" @click="addAdditionalDetail()" icon="mdi-plus-thick" tooltip="Nuevo detalle"
														absolute
														top
														right
													></base-icon-btn>
												</v-row>
											</template>

											<template v-slot:content>
												<base-data-table
													:headers="additionalDetailsHeaders" 
													:items="editedItem.details"
													hideHeader
													elevation="0"
												>
													<template v-slot:item.options="{ item }">
														<base-icon-btn color="red darken-1" @click="removeAdditionalDetail(item)" icon="mdi-close" tooltip="Eliminar"></base-icon-btn>
													</template>

													<template v-slot:item.detail="{ item }">
														<base-text-field v-model="item.detail" 
															:class="{'custom-max-width' : $vuetify.breakpoint.xs}"
															clearable
															counter
															maxlength="30" 
															:rules="[commonValRules.required, validationRules.additionalDetailRules.counter]" 
															required
														></base-text-field>
													</template>
												</base-data-table>
											</template>
										</base-form-container>
									</v-col>
								</v-row>
							</template>
            </base-form-stepper>
          </template>

					<template v-slot:props="{ props }">
						<v-container fluid>
							<v-row>
								<v-col
									v-for="item in props.items"
									:key="item.customCode"
									cols="12"
									sm="6"
									md="4"
									lg="3"
								>
									<base-form-container
										noPaddingContent
										id="productContainer"
									>
										<v-speed-dial	
											v-model="item.showOptions"
										  absolute
											top
											right
											:open-on-hover="!$vuetify.breakpoint.mobile"
											direction="bottom"
											transition="slide-y-transition"
										>
											<template v-slot:activator>
												<v-btn
													v-model="item.showOptions"
													color="blue darken-1"
													dark
													fab
													x-small
												>
													<v-icon v-if="item.showOptions">
														mdi-close
													</v-icon>
													<v-icon v-else>
														mdi-dots-vertical
													</v-icon>
												</v-btn>
											</template>
											
											<v-btn 
												fab
												dark
												x-small
												color="secondary"
												@click="editItem(item)"
											>
												<v-icon>mdi-pencil</v-icon>
											</v-btn>
											<v-btn
												fab
												dark
												x-small
												:color="item.enabled ? 'red' : 'success'"
												@click="enableDisableItem(item, item.enabled ? 2 : 1)"
											>
												<v-icon v-if="item.enabled">mdi-cancel</v-icon>
												<v-icon v-else>mdi-check</v-icon>
											</v-btn>
											<v-btn
												fab
												dark
												x-small
												:color="item.enabledForSales ? 'red' : 'success'"
												@click="enableDisableForSales(item, item.enabledForSales ? 2 : 1)"
											>
												<v-icon v-if="item.enabledForSales">mdi-cart-remove</v-icon>
												<v-icon v-else>mdi-cart-check</v-icon>
											</v-btn>
										</v-speed-dial>

										<template v-slot:content>
											<base-product-img v-model="item.image" :productId="item.id"
												class="align-end"
												gradient="to bottom, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.07)"
												aspect-ratio="1.3"
												contain
											>
												<v-card-title class="white--text py-0 px-2" style="background-color: rgba(0, 0, 0, 0.35);">
													<v-avatar :color="item.enabled ? 'success lighten-5' : 'error lighten-4'" size="25" class="mr-2">
														<v-icon v-if="item.enabled" color="success" size="25">mdi-check-circle</v-icon>
														<v-icon v-else color="error" size="25">mdi-alert-circle</v-icon>
													</v-avatar>
													{{item.customCode}}
												</v-card-title>
											</base-product-img>

											<v-list dense>
												<v-list-item
													v-for="(header, index) in headers.filter(allowedHeaders)"
													:key="index"
												>
													<v-list-item-content class="font-weight-bold" :class="{ 'blue--text': sortBy === header.value }">{{ header.text }}:</v-list-item-content>
													<v-list-item-content class="align-end" :class="{ 'blue--text': sortBy === header.value, 'no-display': header.value==='stock' }">
														<template v-if="header.value==='stock'">
															<v-avatar
																dark
																:color="getColor(item[header.value])"
																class="white--text"
																size="40"
																v-text="item[header.value]"
															></v-avatar>
														</template>
														<template v-else-if="header.value==='salePricePerUnit'">
															<div class="d-flex">
																<v-icon v-if="item.enabledForSales" color="success" class="mt-n1 mr-2">mdi-cart-check</v-icon>
																<v-icon v-else color="error" class="mt-n1 mr-2">mdi-cart-remove</v-icon>
																<span>Bs. {{ item[header.value] }}</span>
															</div>
														</template>
														<template v-else>
															{{ item[header.value] }}	
														</template> 
													</v-list-item-content>
												</v-list-item>
											</v-list>

											<v-btn
												icon
												@click="item.readMore = !item.readMore"
											>
												<v-icon>{{ item.readMore ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
											</v-btn>

											<v-expand-transition>
												<div v-show="item.readMore">
													<v-divider></v-divider>

													<v-list dense>
														<v-list-item
															v-for="(header, index) in readMoreHeaders()"
															:key="index"
														>
															<v-list-item-content class="font-weight-bold" :class="{ 'blue--text': sortBy === header.value }">{{ header.text }}:</v-list-item-content>
															<v-list-item-content class="align-end" :class="{ 'blue--text': sortBy === header.value, 'no-display': header.value==='stock' }">
																<template v-if="header.value==='purchasePricePerUnit'">
																	Bs. {{ item[header.value] }}	
																</template>
																<template v-else-if="header.value==='taxes'">
																	Bs. {{ item[header.value] }}	
																</template>
																<template v-else-if="header.value==='netProfit'">
																	<span :class="{'red--text font-weight-bold': item[header.value] <= 0}">Bs. {{ item[header.value] }}</span>
																</template>
																<template v-else>
																	{{ item[header.value] }}	
																</template> 
															</v-list-item-content>
														</v-list-item>
													</v-list>
												</div>
											</v-expand-transition>
										</template>
									</base-form-container>
								</v-col>
							</v-row>
						</v-container>
					</template>
				</base-data-iterator>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'

export default {
	mixins: [MainExternalData],
	
	data() {
		return {
			title: 'Productos',
      step:1,
      steps:[
				{value: 1, text:'Datos generales'},
				{value: 2, text:'Stock'},
				{value: 3, text:'Imagenes'},
				{value: 4, text:'Más detalles'},
			],
			allowedFiles:['.jpeg', '.jpg', '.png'],
			mainImage:'mainImage',
			imageOne:'imageOne',
			imageTwo:'imageTwo',
			imageThree:'imageThree',
			sortBy: 'customCode',
			products: [],
			headers: [
        { text: "Código", value: "customCode", hidden: true },
				{ text: "Nombre", value: "visualName" },
				
        { text: "Categoría", value: "category" },
        { text: "Fabricante", value: "maker", sortable: false },
        { text: "Hecho en", value: "madeIn", sortable: false },
        { text: "Stock", value: "stock" },
        { text: "Costo unit.", value: "purchasePricePerUnit", sortable: false, readMore: true },
        { text: "Precio unit.", value: "salePricePerUnit" },
        { text: "Impuestos (16%)", value: "taxes", sortable: false, readMore: true },
        { text: "Utilidad", value: "netProfit", sortable: false, readMore: true },

				{ text: "Estado del producto", value: "enabled", hidden: true },
				{ text: "Habilitado para ventas", value: "enabledForSales", hidden: true }
			],
			categories: [],
      countries:[],
			stockForms:[],
			productForms:[],
      editedItem: {
        id: '',
        categoryId: '',
        customCode: '',
        visualName: '',
        maker: '',
        madeIn: '',
        details: [],
        stock: '',
				stockFormId: '',
				stockDetails: '',
				lastPurchaseId: '',
        purchasePricePerUnit: '',
				salePricePerUnit: '',
				productFormId: '',
				productFormDetails: '',
				hasPurchases: false
      },
      defaultItem: {
        id: '',
        categoryId: '',
        customCode: '',
        visualName: '',
        maker: '',
        madeIn: '',
        details: [],
        stock: '',
				stockFormId: '',
				stockDetails: '',
				lastPurchaseId: '',
        purchasePricePerUnit: '',
				salePricePerUnit: '',
				productFormId: '',
				productFormDetails: '',
				hasPurchases: false
			},
			editedImages: {
        mainImage:'',
        imageOne:'',
        imageTwo:'',
        imageThree:''
      },
      defaultImages: {
        mainImage:'',
        imageOne:'',
        imageTwo:'',
        imageThree:''
      },
      editedClothingDetail: {
        size:'',
        color: ''
      },
      defaultClothingDetail: {
        size:'',
        color: ''
      },
			editData: false,
			dialog: false,
			formLoading: false,

			//Purchases
			purchases:[],

			//StockDetails
			editedStockDetails: {
				title: 'Detalles',
				items: []
			},
			defaultStockDetails: {
				title: 'Detalles',
				items: []
			},

			//ProductDetails
			editedProductDetails: {
				items: {}
			},
			defaultProductDetails: {
				items: {}
			},

			//AdditionalDetails
			additionalDetailsHeaders: [
        { text: "Eliminar", value: "options" },
				{ text: "Detalle", value: "detail" }
			],

			//StockFlags
			stockFlags: {
				low: 10,
				high: 30
			},
			
			validationRules: {
        customCodeRules: {
          counter: value => (value.length >= 4 && value.length <= 20) || 'No debe tener más de 20 caracteres, ni menos de 4 caracteres.',
        },
        visualNameRules: {
          counter: value => (value.length >= 3 && value.length <= 50) || 'No debe tener más de 50 caracteres, ni menos de 3 caracteres.',
        },
        makerRules: {
          counter: value => (value.length <= 50) || 'No debe tener más de 50 caracteres.',
        },
        stockRules: {
					counter: value => (value != null && value.toString().length <= 6) || 'No debe tener más de 6 caracteres.',
          number: value => (value >= 0) || 'Debe ser mayor o igual a 0.',
					isValid: (value) => {
						if (this.editedItem.stockFormId == '' || this.editedItem.stockFormId === this.$Const.STOCK_FORM.GENERAL) {
							return true;
						}

						if (value != this.stockDetailsSum) {
							return 'La sumatoria del stock de los detalles debe ser igual al stock total.'
						}
						return true;
					}
        },
        purchasePricePerUnitRules: {
          number: value => (value >= 0) || 'Valor debe ser mayor o gual a 0.',
        },
        salePricePerUnitRules: {
          number: value => (value >= 0) || 'Valor debe ser mayor o gual a 0.',
        },
        detailsRules: {
          counter: value => (value.length <= 250) || 'No debe tener más de 250 caracteres.',
        },

				additionalDetailRules: {
          counter: value => (value != null && value.length >= 4 && value.length <= 30) || 'No debe tener más de 30 caracteres, ni menos de 4 caracteres.',
        },

        clothingSizeRules: {
          counter: value => (value.length <= 5) || 'No debe tener más de 5 caracteres.',
        },
        clothingColorRules: {
          counter: value => (value.length <= 20) || 'No debe tener más de 20 caracteres.',
				},
			}
		}
	},

	computed: {
		formTitle() {
      return this.editData ? "Actualizar datos" : "Nuevo registro";
		},

		salePriceComparisonRule() {
			if (this.editData) {
				return true;
			}
      return () => ( parseFloat(this.editedItem.salePricePerUnit) > parseFloat(this.editedItem.purchasePricePerUnit)) || 'El precio de venta debe ser mayor al costo de compra.'
		},

		stockDetailsMode() {
			return this.editData ? 'ShowData' : 'NewData';
		},

		stockDetailsSum(){
			let total = 0;
			const quantityArray = this.editedStockDetails.items.filter(f => f.stock !== null && f.stock).map(x => parseInt(x.stock))
			if (quantityArray.length > 0) {
				const sum = quantityArray.reduce((a,b) => a+b)
				total += sum	
			}

			this.editedItem.stock = total;
			return total;
		},

		showPurchasesBtn() {
			return this.editData && this.editedItem.hasPurchases;
		},

		purchasesAvailable() {
			return this.purchases.length > 0;
		}
	},

	created() {
		this.listOfCountries();
		this.listOfStockForms();
		this.listOfProductForms();
		this.getStockFlags();
		this.toList();
	},
	
	methods: {
		allowedHeaders(obj){
			if ('readMore' in obj && obj.readMore == true) {
				return false;
			}
			if ('hidden' in obj)
			{
				return obj.visible;
			}
			else {
				return true;
			}
		},

		readMoreHeaders(){
			var newHeaders = this.headers.filter(x => x.readMore == true);
			return newHeaders;
		},

		getColor(stock) {
			if (stock < this.stockFlags.low) return 'red'
			else if (stock > this.stockFlags.high) return 'green'
			else return 'orange'
		},

		stockFormOnChange(value) {
			if (value == 1) {
				this.editedItem.stock = 0;	
			}
			this.editedStockDetails.items = [];
		},

		productFormOnChange(value) {
			var jsonFields = this.productForms.find(x => x.value == value).fields;
			if (!!jsonFields) {
				const fields = JSON.parse(jsonFields);
				this.editedProductDetails.items = fields;
			} else {
				this.editedProductDetails.items = {}
			}
		},

		async listOfCountries(){
      let me=this;
			var countriesArray=[];
			await me.getObjectResponse('api/Parameter/Countries', me.baseRequest).then(data => {
				if (data !== undefined) {
					countriesArray = data.countries; 
					countriesArray.map(function(x){
            me.countries.push({text:x.visualName, value:x.id});
          });
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
    },

		async listOfStockForms(){
      let me=this;
      var stockFormsArray=[];
      let request={
        'companyId': me.baseRequest.companyId,
        'userId': me.baseRequest.userId,
        'ids': me.companyData.stockForms,
			};
			await me.getObjectResponse('api/Parameter/StockFormsByList', request).then(data => {
				if (data !== undefined) {
					stockFormsArray = data.stockForms; 
					stockFormsArray.map(function(x){
            me.stockForms.push({text:x.visualName, value:x.id});
          });
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
    },

		async listOfProductForms(){
      let me=this;
      var productFormsArray=[];
      let request={
        'companyId': me.baseRequest.companyId,
        'userId': me.baseRequest.userId,
        'ids': me.companyData.productForms,
			};
			await me.getObjectResponse('api/Parameter/ProductFormsByList', request).then(data => {
				if (data !== undefined) {
					productFormsArray = data.productForms; 
					productFormsArray.map(function(x){
            me.productForms.push({text:x.visualName, value:x.id, fields: x.fields});
          });
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
    },

		async getStockFlags(){
      let me=this;
      let request={
        'companyId': me.baseRequest.companyId,
        'userId': me.baseRequest.userId
      };
      await me.getObjectResponse('api/Company/GetStockFlags', request).then(data => {
        if (data === undefined) {
					return;
        }
				me.stockFlags = Object.assign({}, data);
      }).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
    },

		addCustomProperties(originalProducts) {
			let products = originalProducts;
			products.map(x => {
				x.showOptions = false;
				x.readMore = false;
			});
			return products;
		},

		async toList(){
			let me=this;
			try {
        me.SHOW_LOADING()
				me.products=[];
				await me.getObjectResponse('api/Product/GetAll', me.baseRequest).then(data => {
					if (data === undefined) {
						return;
					}
					me.products = me.addCustomProperties(data.products);
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},

		async listOfCategories(){
      let me=this;
			var result = false;
			var categoriesArray=[];
			await me.getObjectResponse('api/ProductCategory/SelectAll', me.baseRequest).then(data => {
				if (data !== undefined) {
					categoriesArray = data.categories; 
					categoriesArray.map(function(x){
						me.categories.push({text:x.visualName, value:x.id, enabled:x.enabled});
          });
          result = true;
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
      return result;
		},

		async editItem(item) {
			let me=this;
			try {
        me.SHOW_LOADING()
				if (!await me.listOfCategories()) {
					return;
				}

				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'id': item.id,
				};
				await me.getObjectResponse('api/Product/GetById', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.editedItem = Object.assign({}, data.product);
					me.editedItem.details = me.parseAdditionalDetails(data.product.details, false);
					me.editedImages = Object.assign({}, data.pictures);
					me.editedStockDetails.items = me.parseStockDetails(me.editedItem.stockDetails, me.editedItem.stockFormId, false);
					me.editedProductDetails.items = me.parseProductDetails(me.editedItem.productFormDetails, me.editedItem.productFormId, false);
					me.editData = true;
					me.dialog = true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},

		parseStockDetails(stockDetails, stockFormId, serialize){
			if (serialize) {
				if (stockFormId === this.$Const.STOCK_FORM.GENERAL) {
					return null;
				}
				let result = JSON.stringify(stockDetails)
				return result;
			} else {
				if (stockFormId === this.$Const.STOCK_FORM.GENERAL) {
					return [];
				}
				let result = JSON.parse(stockDetails)
				return result;
			}
		},

		parseProductDetails(productDetails, productFormId, serialize){
			if (serialize) {
				if (productFormId === 1) {
					return null;
				}
				let result = JSON.stringify(productDetails)
				return result;
			} else {
				if (productFormId === 1) {
					return {};
				}
				let result = JSON.parse(productDetails)
				return result;
			}
		},
		
		async open(){
			let me=this;
			try {
				me.SHOW_LOADING()
				if (!await me.listOfCategories()) {
					return;
				}
				me.dialog = true;
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},
		
		close() {
      this.dialog = false;
      this.clean();
		},
		
		clean(){
      this.step = 1;
      this.editData = false;
      this.editedItem = Object.assign({}, this.defaultItem);
			this.editedItem.details = [];
			// console.log('product-clean', this.editedItem)
			this.purchases = [];
      this.editedImages = Object.assign({}, this.defaultImages);
			this.editedStockDetails = Object.assign({}, this.defaultStockDetails);
			this.editedProductDetails = Object.assign({}, this.defaultProductDetails);
      this.editedClothingDetail = Object.assign({}, this.defaultClothingDetail);
      this.$refs.ProductForm.reset();
		},
		
		async save() {
			let me=this;
			try {
				me.SHOW_LOADING()
				me.formLoading=true;
				if (me.editData) {
					let request={
						'companyId': me.baseRequest.companyId,
						'userId': me.baseRequest.userId,

						'id': me.editedItem.id,
						'categoryId': me.editedItem.categoryId,
						'customCode': me.editedItem.customCode,
						'visualName': me.editedItem.visualName,
						'maker': me.editedItem.maker,
						'madeIn': me.editedItem.madeIn,
						'details': me.parseAdditionalDetails(me.editedItem.details, true),
						'salePricePerUnit': parseFloat(me.editedItem.salePricePerUnit),
						'productFormDetails': me.parseProductDetails(me.editedProductDetails.items, me.editedItem.productFormId, true),

						'mainImage': me.editedImages.mainImage,
						'imageOne': me.editedImages.imageOne,
						'imageTwo': me.editedImages.imageTwo,
						'imageThree': me.editedImages.imageThree,
					};
					await me.getObjectResponse('api/Product/Update', request).then(data => {
						if (data == undefined) {
              return;
            }
						me.addOrUpdateListItem(data.product)
						me.close();
					}).catch(function(error){
						me.$swal(me.swalConfig.errorTitle, error.message, 'error');
					});
				} 
				else {
					let request={
						'companyId': me.baseRequest.companyId,
						'userId': me.baseRequest.userId,

						'categoryId': me.editedItem.categoryId,
						'customCode': me.editedItem.customCode,
						'visualName': me.editedItem.visualName,
						'maker': me.editedItem.maker,
						'madeIn': me.editedItem.madeIn,
						'details': me.parseAdditionalDetails(me.editedItem.details, true),
						'stock': parseInt(me.editedItem.stock, 10),
						'stockFormId': parseInt(me.editedItem.stockFormId, 10),
						'stockDetails': me.parseStockDetails(me.editedStockDetails.items, me.editedItem.stockFormId, true),
						'purchasePricePerUnit': parseFloat(me.editedItem.purchasePricePerUnit),
						'salePricePerUnit': parseFloat(me.editedItem.salePricePerUnit),
						'productFormId': parseInt(me.editedItem.productFormId, 10),
						'productFormDetails': me.parseProductDetails(me.editedProductDetails.items, me.editedItem.productFormId, true),

						'mainImage': me.editedImages.mainImage,
						'imageOne': me.editedImages.imageOne,
						'imageTwo': me.editedImages.imageTwo,
						'imageThree': me.editedImages.imageThree,
					};
					await me.getObjectResponse('api/Product/Create', request).then(data => {
						if (data == undefined) {
              return;
            }
						me.addOrUpdateListItem(data.product)
						me.close();
					}).catch(function(error){
						me.$swal(me.swalConfig.errorTitle, error.message, 'error');
					});
				}
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.formLoading=false;
      }
		},

		addOrUpdateListItem(item){
			let me=this;
			item['readMore'] = false;
			if (!!me.products.find((x) => x.id === item.id)) {
				const index = me.products.findIndex((x) => x.id === item.id)
				me.products.splice(index, 1, item);
			} else {
				me.products.push(item);
			}
		},

		async enableDisableItem(item, action){
			let me = this;
			if (!await me.showEnableDisableConfirmDialog(action, `el producto <strong>${item.visualName}</strong>`)) {
				return;
			}

			if (action==1) {
				me.enable(item);
			}
			else if (action==2) {
				me.disable(item);
			}
		},
		
		async enable(item){
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'id': item.id,
				};
				await me.getBaseResponse('api/Product/Enable', request).then(data => {
					if (data) {
						item.enabled = true;
						me.showSuccessModal();
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},
		
		async disable(item){
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'id': item.id,
				};
				await me.getBaseResponse('api/Product/Disable', request).then(data => {
					if (data) {
						item.enabled = false;
						me.showSuccessModal();
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},

		async enableDisableForSales(item, action){
			let me = this;
			const text = action==1 ? 'habilitar' : 'deshabilitar';
			if (!await me.showConfirmationDialog(`Estás a punto de <strong>${text}</strong> las ventas para el producto <strong>${item.visualName}</strong>`, `Si, ${text} las ventas!`)) {
				return;
			}

			if (action==1) {
				me.enableForSales(item);
			}
			else if (action==2) {
				me.disableForSales(item);
			}
		},
		
		async enableForSales(item){
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'id': item.id,
				};
				await me.getBaseResponse('api/Product/EnableForSales', request).then(data => {
					if (data) {
						item.enabledForSales = true;
						me.showSuccessModal();
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},
		
		async disableForSales(item){
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'id': item.id,
				};
				await me.getBaseResponse('api/Product/DisableForSales', request).then(data => {
					if (data) {
						item.enabledForSales = false;
						me.showSuccessModal();
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},
		
		onFileChange(name, file){
			switch (name) {
				case this.mainImage:
					this.editedImages.mainImage = '';
					if (file){
						this.editedImages.mainImage = file.base64;
					}
					break;
				case this.imageOne:
					this.editedImages.imageOne = '';
					if (file){
						this.editedImages.imageOne = file.base64;
					}
					break;
				case this.imageTwo:
					this.editedImages.imageTwo = '';
					if (file){
						this.editedImages.imageTwo = file.base64;
					}
					break;
				case this.imageThree:
					this.editedImages.imageThree = '';
					if (file){
						this.editedImages.imageThree = file.base64;
					}
					break;
				default:
					break;
			};
		},

		//Purchases
		async getAvailablePurchases() {
			let me=this;
			try {
        me.SHOW_LOADING()
				me.purchases=[];
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'id': me.editedItem.id,
				};
				await me.getObjectResponse('api/Product/GetAvailablePurchases', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.purchases = data.purchases;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},

		//AdditionalDetails
		addAdditionalDetail() {
			this.editedItem.details.push({detail: null});
		},

		removeAdditionalDetail(item) {
			const index = this.editedItem.details.indexOf(item);
      if (index !== -1) {
				this.editedItem.details.splice(index, 1);
			}
		},

		parseAdditionalDetails(details, serialize){
			if (serialize) {
				if (details.length <= 0) {
					return null;
				}
				let result = [];
				for (let i = 0; i < details.length; i++) {
					const element = details[i];
					result.push(element.detail.trim());
				}
				return JSON.stringify(result);
			} else {
				let result = [];
				if (details === null || details === undefined || details == '') {
					return result;
				}
				
				try {
					var data = JSON.parse(details);
					for (let i = 0; i < data.length; i++) {
						const element = data[i];
						result.push({detail: element});
					}
				} catch {
					if (details != '') {
						result.push({detail: details});
					}
				}
				return result;
			}
		},

		//Report
		async mainReportView() {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
				};
				await me.getObjectResponse('api/Product/GetMainReportView', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.$Tools.downloadDocument(data.fileName, data.fileContent);
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		}
	},
}
</script>

<style scoped>
	.no-display{
		display: block;
	}
	.v-speed-dial--right {
		right: 5px;
	}
	.v-speed-dial--top {
		top: 5px;
	}
	.v-speed-dial--fixed, .v-speed-dial--absolute {
    z-index: 1;
	}
</style>